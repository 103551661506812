import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChecklistState } from '@app/checklist/state/checklist.state';

const getChecklistFeatureState = createFeatureSelector<ChecklistState>('checklist');

export const getChecklists = createSelector(getChecklistFeatureState, (state) => state.checklists);

export const getNbOfItemsNotChecked = createSelector(
  getChecklistFeatureState,
  (state) => state?.nbOfItemsNotChecked ?? 0,
);

export const isLoading = createSelector(getChecklistFeatureState, (state) => state.loading);
